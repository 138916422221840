.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    color: #ffffff;
    font-size: 1.2rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 350px; /* maintain width */
    padding: 10px;
    margin: 10px 0;
    background-color: transparent;
    border: 1px solid #00abf0;
    color: #f0f0f0;
    box-shadow: 0px 10px 20px rgba(207, 203, 203, 0.3);
  
  }
  .btn-contact {
    text-align: center;
    margin-top: 5px;
  }
  
  
  
  .contact-form h2{
    color: #f7f7f7;
    margin-bottom: 20px;
  }
  
  .alert {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
    transition: opacity 0.5s ease;
  }
  
  @media screen and (min-width: 768px) { 
  
  .grid {
    position: fixed;
    bottom: 0;
  }
  .home-container{
    margin-bottom: 150px;
  }
  }