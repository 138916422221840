.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: relative;
  margin-top: 5px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}

.menu-item {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  transition: transform 0.3s, opacity 0.3s;
}

.icons .menu-item:hover {
  transform: translate(-50%, -50%) scale(1.1) rotate(15deg);
  opacity: 0.8;
}
