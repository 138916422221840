.project-link {
  color: white;
  margin-left: 10px;
}

.project-img {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 35px;
  height: 230px;

}
.project-img::before {
  content: "";
  background: linear-gradient(90.21deg, #00868B -5.91%, #CD950C 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.project-img:hover::before {
  height: 100%;
}
.project-txt {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.project-img:hover .project-txt {
  top: 50%;
  opacity: 1;
}
.project-txt h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.project-txt span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
