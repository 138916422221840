
.navbar {
    display: flex;
    align-items: baseline;
    margin-right: 1rem;
    margin-top: 1rem;
    
  }
  
  .navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
}

  .nav-links {
    list-style: none;
    display: flex;
    margin-left: 20px;
  }
  
  .nav-links li {
    margin-right: 20px; /* Add some space between links */
  }
  
  .nav-links a {
    text-decoration: none;
    color: white; /* Set link color */
    font-size: 18px;
    transition: color 0.3s ease-in-out;
  }
  
  .nav-links a:hover {
    color: #00abf0; /* Set link color on hover */
  }
  
  .Logo {
    text-decoration: none;
    font-weight: bold;
    font-size: 2.5rem;
    color: white;

}
  