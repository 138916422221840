.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 1px solid #00abf0;
    border-radius: 4px;
    background: #00abf0;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    margin-bottom: 1.5%;
    text-decoration: none;
    display: inline-block;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: #00b7ff;
    border-color:#00b7ff ;
  }
  
  .button--inverse {
    background: transparent;
    color: #00abf0;
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: white;
    background: #00abf0;
  }
  