
.root {
  position: initial;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}



.grid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow-x: hidden;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.1);
  }
  
  .icon {
    color: #00abf0 !important;
    margin-bottom: 10px;
  }
  
  .icon:hover {
    transform: scale(1.1);
  }
  
  .info a:not(:hover) {
    color: #00abf0;
  }
  
  .info a:hover {
    color: #00b7ff;
  }
  
  
